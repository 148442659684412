<template>
  <div>
    <main class="main-content">
			<div class="alliances-banner_wrap">
				<div class="alliances-banner_inner">
					<div class="container">
						<div class="row">
							<div class="col-md-5 col-sm-12 col-md-offset-1 text-left">
								<div class="header-page">
									<h2 class="header-page_title">
										{{ $t('lang.alliances.our_new_alliance') }}
									</h2>
									<h3 class="header-page_subtitle active color-black">
										{{ $t('lang.alliances.colombian_association_of_purchasing_professionals') }}
									</h3>
									<div class="header-page_text">
										<p>
											{{ $t('lang.alliances.know_the_benefits_we_have_for_your_company') }}
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<img src="../assets/images/landing/logo_acopc.png" style="width:500px;heigth:300px" alt="Es hora de usar HandelBay en tu día a día">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container">
					<div class="section-page_wrap">
							<div class="row">
									<div class="col-md-6 col-sm-6 col-md-offset-1">
										<div class="header-page">
											<h3 class="header-page_subtitle active">
												{{ $t('lang.alliances.our_alliance') }}
											</h3>
											<div class="header-page_text">
												<p>
													{{ $t('lang.alliances.lack_of_time_to_carry_out_strategic_activities_because_they_are_busy_fighting_fires_or_dedicated_to_operational_tasks?') }}
												</p>
											</div>

											<div class="header-page_action">
												<a :href="global.sign_up" class="btn header-page_button">
												{{ $t('lang.alliances.sign_up') }}
												</a>
											</div>
										</div>
									</div>
									<div class="col-md-4 col-sm-6">
											<img src="../assets/images/landing/handelbay/handelbay_computador.png" alt="Nuestra alianza" class="img-full-width">
									</div>
							</div>
					</div>
					<div class="section-page_wrap">
							<div class="row">
									<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active">
												{{ $t('lang.alliances.what_is_ACOP?') }}
											</h3>
											<div class="header-page_text">
												<p>
													{{ $t('lang.alliances.the_colombian_association_of_purchasing_professionals_ACOPC_is_a_private_civil_entity') }}
												</p>
												<p>
													{{ $t('lang.alliances.Founded_in_2017_the_Associations_primary_objective_is_to_achieve_the_professionalization_of_the_supply_purchasing_discipline') }}
												</p>
												<p>
													{{ $t('lang.alliances.we_have_physical_presence_of_representatives_certified_executives') }}
												</p>
											</div>
										</div>
									</div>
							</div>
					</div>
			</div>
			<div class="section-page_wrap">
					<section id="handelbay-background-curva">
					</section>
			</div>
			<section id="benefits">
					<div class="container">
							<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
								<div class="header-page">
									<h3 class="header-page_subtitle active">
										{{ $t('lang.alliances.benefits') }}
									</h3>
								</div>
								<div class="benefits_wrap">
									<div class="benefits_items">
										<div class="benefits_item">
											<div class="benefits_header">
												<img alt="Servicio de calidad" src="../assets/images/landing/handelbay/Icono_ACOPC-02.png">
											</div>
											<div class="benefits_body">
												<div class="benefits_body-title">
													<h3 class="title">
														{{ $t('lang.alliances.individual_seminar') }}
													</h3>
												</div>
												<div class="benefits_body-description">
													<p>
														{{ $t('lang.alliances.5%_discount_on_open_seminars') }}
													</p>
												</div>
											</div>
											<div class="benefits_footer">
												<a href="https://www.acopc.com.co/capacitacion/" target="_blank" class="btn benefits_footer-action">
													{{ $t('lang.alliances.see_more') }}
												</a>
											</div>
										</div>
										<div class="benefits_item">
											<div class="benefits_header">
												<img alt="Servicio de calidad" src="../assets/images/landing/handelbay/Icono_ACOPC2-02.png">
											</div>
											<div class="benefits_body">
												<div class="benefits_body-title">
													<h3 class="title">
														{{ $t('lang.alliances.group_seminar') }}
													</h3>
												</div>
												<div class="benefits_body-description">
													<p>
														{{ $t('lang.alliances.10%_discount_on_private_seminars') }}
													</p>
												</div>
											</div>
											<div class="benefits_footer">
												<a href="https://www.acopc.com.co/seminarios-cerrados/" target="_blank" class="btn benefits_footer-action">
													{{ $t('lang.alliances.see_more') }}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
					</div>
			</section>
			<section id="handelbay-background-curva-bottom">
			</section>

			<div class="container">
					<div class="section-page_wrap">
							<div class="row">
									<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active">
												{{ $t('lang.alliances.contact') }}
											</h3>
										</div>
									</div>
							</div>
							<div class="row">
									<div class="col-md-3 col-sm-4 hidden-xs col-md-offset-2">
											<div class="img-profile">
													<img src="../assets/images/landing/handelbay/handelbay_agenda_profile.png" alt="Si quieres tener más información de los beneficios de esta alianza, ¡contáctanos!" class="img-full-width">
											</div>
									</div>
									<div class="col-md-6 col-sm-7">
											<div class="auth-form_wrap">
													<div class="auth-form_head">
															<div class="auth-form_head-title">
																	<h3 class="title">
																		{{ $t('lang.alliances.if_you_want_more_information_about_the_benefits_of_this_alliance_contact_us!') }}
																	</h3>
															</div>
													</div>
													<div class="auth-form_body">
															<form action="" class="form-valid">
																	<div class="row">
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="name">
																								{{ $t('lang.alliances.full_name') }}
																							</label>
																							<input type="text" name="name" id="name" required="required" class="floating inputs" title="Nombre requerido">
																					</div>
																			</div>
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="email">
																								{{ $t('lang.alliances.email') }}
																							</label>
																							<input type="email" name="email" id="email" required="required" class="floating inputs" title="Correo invalido." pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$">
																					</div>
																			</div>
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="phone">
																								{{ $t('lang.alliances.telephone') }}
																							</label>
																							<input type="text" name="phone" id="phone" required="required" class="floating inputs">
																					</div>
																			</div>
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="company_name">
																								{{ $t('lang.alliances.name_of_your_company') }}
																							</label>
																							<input type="text" name="company_name" id="company_name" required="required" class="floating inputs">
																					</div>
																			</div>
																			<div class="col-md-12">
																					<div class="form-group-check">
																							<div class="squared">
																									<input type="checkbox" required="" id="downloader-privacity">
																									<label class="downloader-privacity" for="downloader-privacity"></label>
																							</div>
																							<div class="check_text inline-block">
																									<div class="titles">
																											<label for="downloader-privacity">
																												{{ $t('lang.alliances.i_would_like_to_receive_information_and_offers_about_HandelBay') }}
																											</label>
																									</div>
																							</div>
																					</div>
																					<div class="auth-form_body-info">
																							<p>
																								{{ $t('lang.alliances.by_signing_up_you_agree_to_our_terms_of_service_and_privacy_policy') }}
																							</p>
																					</div>
																					<div class="sendAction">
																							<hr>
																							<button class=" btn btn-handelbay-success">
																								{{ $t('lang.alliances.sign_up') }}
																							</button>
																					</div>
																			</div>
																	</div>
															</form>
													</div>
											</div>
									</div>
							</div>
					</div>
			</div>
    </main>
  </div>
</template>
<script>
import global from "@/components/Global.vue"

export default {
	data(){
		return{
			global: global
		}
	}
}
</script>